import React from "react";
import Typography from "root/components/Typography";
import stick from "root/assets/stick-about.svg";

import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.about_subs}>
        <div className={styles.normalDesc}>
          <div className={styles.subtitle}>
            <Typography color="blue" weight="medium" variant="h3">
              {t("about.waitMore.subtitle")}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography color="black">
              {t("about.waitMore.description")}
            </Typography>
          </div>
        </div>
        <img className={styles.stick} alt="stick" src={stick} />
      </div>
    </div>
  );
};

export default Hero;
