import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import Button from "root/components/Button";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const mobileW = 800;

const AboutSmiles = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "heroImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dimensions = useWindowDimensions();

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography variant="h2" color="blue" weight="medium">
              {t("about.smiles.subtitle")}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography variant="body" color="black">
              {t("about.smiles.description")}
            </Typography>
          </div>
          <Link to={t("pages.careers.url")}>
            <Button color="yellow" size="medium">
              <Typography variant="body">{t("about.smiles.join")}</Typography>
            </Button>
          </Link>
        </div>
        <div className={styles.right}>
          <Img
            className={styles.image}
            alt="institutions"
            fluid={data.file.childImageSharp.fluid}
            objectFit="cover"
          />
          <div className={styles.grid}>
            <Grid
              columns={dimensions.width > mobileW ? 24 : 18}
              lines={dimensions.width > mobileW ? 18 : 14}
              color="blue"
              opacity={0.5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSmiles;
