import React from "react";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import aboutLeft from "root/assets/aboutLeft.png";
import classNames from "classnames";
import stick from "root/assets/aboutResultStick.svg";

import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const mobileW = 800;

const AboutResult = () => {
  const { t } = useTranslation();
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <img className={styles.aboutLeft} alt="aboutLeft" src={aboutLeft} />
      <Grid
        columns={dimensions.width > mobileW ? 0 : 20}
        lines={dimensions.width > mobileW ? 0 : 26}
        color="yellow"
        opacity={0.5}
      />
      {/* ESQUERDA */}
      <div className={styles.left}>
        <img className={styles.aboutLeftDesk} alt="aboutLeft" src={aboutLeft} />

        <div className={styles.grid}>
          <Grid
            columns={dimensions.width > mobileW ? 52 : 0}
            lines={dimensions.width > mobileW ? 27 : 0}
            color="yellow"
            opacity={0.5}
          />
        </div>
        <div className={styles.goal}>
          <div className={styles.title}>
            <Typography color="blue" weight="medium" variant="h3">
              {t("about.result.subtitle2")}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography color="black">
              {t("about.result.description2")}
            </Typography>
          </div>
        </div>
      </div>

      {/* DIREITA */}
      <div className={styles.right}>
        <div className={styles.goal}>
          <div className={classNames(styles.title, styles.titleMobile)}>
            <Typography color="blue" weight="medium" variant="h3">
              {t("about.result.subtitle")}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography color="black">
              {t("about.result.description")}
            </Typography>
          </div>
          <div className={styles.gridMobile}>
            <Grid
              className={styles.gridMobile}
              columns={dimensions.width > mobileW ? 0 : 18}
              lines={dimensions.width > mobileW ? 0 : 12}
              color="yellow"
              opacity={0.5}
            />
          </div>
          <img className={styles.stick} alt="stick" src={stick} />
        </div>
      </div>
    </div>
  );
};

export default AboutResult;
