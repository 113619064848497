import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./index.module.css";

const AboutApostrophe = ({ color }) => {
  const className = classNames(styles.apostrophe, styles[color]);

  return (
    <svg
      width="35"
      height="28"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9677 3.08244C10.3943 2.93907 9.84468 2.86738 9.319 2.86738C7.50299 2.86738 5.97372 3.63202 4.73118 5.16129C3.53644 6.64277 2.96296 8.50657 3.01075 10.7527C3.91876 10.3226 4.80287 10.1075 5.66308 10.1075C7.0012 10.1075 8.14815 10.5854 9.10394 11.5412C10.0597 12.4492 10.5376 13.5484 10.5376 14.8387C10.5376 16.3202 10.0597 17.5627 9.10394 18.5663C8.14815 19.5221 6.9773 20 5.5914 20C3.87097 20 2.50896 19.3071 1.50538 17.9212C0.501792 16.4875 0 14.4564 0 11.828C0 8.24373 0.83632 5.37634 2.50896 3.2258C4.22939 1.07527 6.45161 0 9.17563 0C9.89247 0 10.7766 0.119475 11.828 0.358423L10.9677 3.08244ZM24.3728 3.08244C23.7993 2.93907 23.2497 2.86738 22.724 2.86738C20.908 2.86738 19.3787 3.63202 18.1362 5.16129C16.9415 6.64277 16.368 8.50657 16.4158 10.7527C17.3238 10.3226 18.2079 10.1075 19.0681 10.1075C20.4062 10.1075 21.5532 10.5854 22.509 11.5412C23.4648 12.4492 23.9427 13.5484 23.9427 14.8387C23.9427 16.3202 23.4648 17.5627 22.509 18.5663C21.5532 19.5221 20.3823 20 18.9964 20C17.276 20 15.914 19.3071 14.9104 17.9212C13.9068 16.4875 13.405 14.4564 13.405 11.828C13.405 8.24373 14.2413 5.37634 15.914 3.2258C17.6344 1.07527 19.8566 0 22.5806 0C23.2975 0 24.1816 0.119475 25.233 0.358423L24.3728 3.08244Z"
        className={className}
      />
    </svg>
  );
};

AboutApostrophe.propTypes = {
  color: PropTypes.oneOf(["white", "yellow", "purple"]).isRequired,
};

export default AboutApostrophe;
