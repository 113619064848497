import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import aboutRight from "root/assets/aboutRight.png";
import stick from "root/assets/stick-about.svg";
import classNames from "classnames";

import styles from "./index.module.css";

const mobileW = 800;

const Hero = ({
  translations: {
    title,
    bigDescription,
    subtitle,
    description,
    subtitle2,
    description2,
  },
}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <Img
        className={styles.image_hero}
        alt="hero"
        fluid={data.file.childImageSharp.fluid}
        objectFit="cover"
      />

      <div className={styles.grid}>
        <Grid
          columns={dimensions.width > mobileW ? 54 : 24}
          lines={30}
          color="yellow"
          opacity={0.5}
        />
      </div>

      <img className={styles.aboutRight} alt="aboutRight" src={aboutRight} />

      <div className={styles.title}>
        <Typography color="blue" weight="medium" variant="h1">
          {title}
        </Typography>
      </div>

      <div className={styles.goal}>
        <div className={styles.description}>
          <Typography variant="body" color="black">
            {bigDescription}
          </Typography>
        </div>
        <div className={styles.space} />
      </div>

      <div className={styles.about_subs}>
        <img className={styles.stick} alt="stick" src={stick} />
        <div className={classNames(styles.one_col, styles.col_left)}>
          <div className={styles.subtitle}>
            <Typography color="blue" weight="medium" variant="h3">
              {subtitle}
            </Typography>
          </div>
          <div className={styles.subtitleDescription}>
            <Typography color="black">{description}</Typography>
          </div>
        </div>

        <div className={classNames(styles.one_col, styles.col_right)}>
          <div className={styles.subtitle}>
            <Typography color="blue" weight="medium" variant="h3">
              {subtitle2}
            </Typography>
          </div>
          <div className={styles.subtitleDescription}>
            <Typography color="black">{description2}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    subtitle2: PropTypes.string.isRequired,
    description2: PropTypes.string.isRequired,
    subtitle3: PropTypes.string.isRequired,
    description3: PropTypes.string.isRequired,
    bigDescription: PropTypes.string.isRequired,
  }).isRequired,
};

export default Hero;
