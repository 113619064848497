import React from "react";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import Apostrophe from "root/components/SvgColor/AboutApostrophe";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const mobileW = 800;

const AboutResultVideo = () => {
  const { t } = useTranslation();
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        {/* ESQUERDA */}
        <div className={styles.grid2}>
          <Grid
            columns={dimensions.width > mobileW ? 30 : 10}
            lines={dimensions.width > mobileW ? 27 : 9}
            color="yellow"
            opacity={0.5}
          />
        </div>
        <div className={styles.goal}>
          <div className={styles.title}>
            <Typography color="blue" weight="medium" variant="h3">
              {t("about.video.subtitle")}
            </Typography>
          </div>
          <div className={styles.youtubeVideo}>
            <iframe
              style={{
                width: "100%",
                height: "100%",
              }}
              title="sioslife video"
              src="https://www.youtube.com/embed/fLB4i9SpJN8"
              frameBorder="0"
            />
          </div>
        </div>
      </div>

      {/* DIREITA */}
      <div className={styles.right}>
        <div className={styles.goal}>
          <div className={styles.quote}>
            <div className={styles.aboutApostrophe}>
              <Apostrophe color="purple" />
            </div>
            <Typography color="black">
              {t("about.video.description")}
            </Typography>
          </div>
          <div className={styles.author}>
            <Typography color="grey">
              {t("about.video.description2")}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutResultVideo;
